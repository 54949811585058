<template>
  <div id="hp">
    <div class="inner_banner rel">
      <a class="db" href="javascript:void(0);">
        <img :src="require('@/assets/images/ent_bg.png')" />
      </a>
      <div class="abs ent_profile">
        <div class="img_box auto ">
          <img :src="getImgUrl(companyInfo.companyLogo)" />
          <i
            class="abs iconfont icon-renzheng renzheng"
            v-if="companyInfo.isFlag"
          ></i>
        </div>
        <div class="name tc f18 cfff mt10">{{ companyInfo.companyName }}</div>
        <a
          href="javascript:void(0);"
          class="db tc auto mt5"
          @click="showForm()"
          v-if="!companyInfo.isFlag"
          >去认领</a
        >
      </div>
    </div>

    <main class="ztb_main policy_main">
      <div class="ztb_box">
        <div class="ent_page cf">
          <div
            class="dib fl tc"
            :class="{ active: isHomePage }"
            @click="togglePage('home')"
          >
            厂商主页
          </div>
          <div
            class="dib fl tc"
            :class="{ active: !isHomePage }"
            @click="togglePage('article')"
          >
            厂商文章
          </div>
        </div>

        <div v-show="isHomePage">
          <div class="ent_brief">
            <h3>公司简介</h3>
            <p>{{ companyInfo.companyProfile }}</p>
          </div>

          <div class="ent_brief">
            <h3>产品解决方案</h3>
            <p v-html="companyInfo.companyProducts"></p>
          </div>
        </div>

        <!-- 文章列表 -->
        <div class="pl_list_box" v-show="!isHomePage">
          <ul class="pl_list article-list" v-if="companyArticles.list.length">
            <li
              v-for="item in companyArticles.list"
              :key="item.id"
              class="article-item"
            >
              <router-link
                :to="{ path: '/newsDetail', query: { id: item.id } }"
                class="db w100p item"
              >
                <div class="img_box">
                  <img :src="getImgUrl(item.image)" class="db" />
                </div>
                <div class="brief w100p article-info">
                  <div class="title">{{ item.title }}</div>
                  <!-- 你问我为啥用title,因为接口没这个字段啊  ┻━┻︵╰(‵□′)╯︵┻━┻  -->
                  <div class="intro">{{ item.title }}</div>
                  <div class="date_price cf">
                    <div class="date fl" v-formatTime>
                      {{ item.createdTime }}
                    </div>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
          <no-data v-else></no-data>
          <div class="pagnation" v-if="companyArticles.list.length">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryParams.PageIndex"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="queryParams.PageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="companyArticles.totalCount"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="ztb_latest pb30">
        <div class="sidebar_box">
          <div class="title">
            <span>基本信息</span>
          </div>
          <ul class="ent_side">
            <li>
              <label>企业分类</label>
              <div class="sort">
                <div v-for="item in companyInfo.classify">{{ item }}</div>
              </div>
            </li>
            <li>
              <label>公司规模</label>
              <span>{{ companyInfo.companySize }}人</span>
            </li>
            <li>
              <label>注册时间</label>
              <span v-formatTime>{{ companyInfo.createdTime }}</span>
            </li>
            <li>
              <label>联&ensp;系&ensp;人</label>
              <span>{{ companyInfo.contacts }}</span>
            </li>
            <li>
              <label>联系电话</label>
              <span>{{ companyInfo.phones }}</span>
            </li>
            <li>
              <label>座&emsp;&emsp;机</label>
              <span>{{ companyInfo.telPhones }}</span>
            </li>
            <li class="bd_n">
              <label>邮&emsp;&emsp;箱</label>
              <span>{{ companyInfo.email }}</span>
            </li>
          </ul>
          <div class="title mt30">
            <span>公司网址</span>
          </div>
          <ul class="ent_side">
            <li class="bd_n">
              <label style="line-height: 2;">公司网址</label>
              <span class="url">{{ companyInfo.url }}</span>
            </li>
          </ul>
          <div class="title mt30">
            <span>公司地址</span>
          </div>
          <ul class="ent_side">
            <li class="bd_n">
              <label style="line-height: 2;">公司地址</label>
              <span class="address">{{ companyInfo.address }}</span>
            </li>
          </ul>
        </div>
      </div>
    </main>
  </div>
  <div class="success_box rel" v-show="showClaimForm">
    <i class="icon-false iconfont abs" @click="showClaimForm = false"></i>
    <img :src="require('@/assets/images/yxb-white.png')" class="succ_logo db" />
    <div class="info_box auto ">
      <h3 class="pt30">认证公司名称</h3>
      <div class="f16 name">{{ companyInfo.companyName }}</div>
      <h3 class="pt10">请提交营业执照</h3>

      <el-upload
        :limit="1"
        ref="uploadRef"
        class="avatar-uploader"
        :class="{ disable: hideUpload }"
        action="#"
        list-type="picture-card"
        :auto-upload="false"
        :on-change="fileChange"
      >
        <template #default>
          <div
            class="img_box_1 auto mt5 border-none"
            :class="{ notshow: hideUpload }"
          >
            <img
              :src="require('@/assets/images/yy.png')"
              class="w100p cert-pic"
            />
          </div>
        </template>
        <template #file="{file}">
          <div class="operate-container">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="handlePicPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </template>
      </el-upload>
      <div class="submit-btn">
        <el-button type="primary" @click="claimCompany()">提交</el-button>
      </div>
    </div>
  </div>
  <el-dialog v-model="preview.show">
    <img width="100%" :src="preview.url" class="preview-img" alt="" />
  </el-dialog>
  <div class="mask" v-if="showClaimForm"></div>
</template>
<script setup>
import { ref, reactive, computed } from "vue";
import { useStore } from "vuex";
import $api from "@/api";
import useCompanyInfo from "@/composable/company/useCompanyInfo";
import useCompanyArticle from "@/composable/company/useCompanyArticle";
import usePagination from "@/composable/universal/usePagination";
import useUploadFile from "@/composable/universal/useUploadFile";
import NoData from "@/components/nothing/noData.vue";
import Dialog from "@/utils/ui/dialog";
import uploadImg from "@/utils/file/uploadImg"
import { getImgUrl } from "@/utils/imageRelated";

const isHomePage = ref(true);
const togglePage = (type) => (isHomePage.value = type === "home");

const { companyInfo } = useCompanyInfo();
const { queryParams, companyArticles, getCompanyArticle } = useCompanyArticle();
const { handleSizeChange, handleCurrentChange } = usePagination(queryParams, getCompanyArticle);

const preview = reactive({
  show: false,
  url: "",
});
const {
  hideUpload,
  imgs,
  uploadRef,
  fileChange,
  handlePicPreview,
  handleRemove,
} = useUploadFile(preview);

const isLogin = computed(() => store.state.userState.token);
const showClaimForm = ref(false);
const store = useStore();
const claimCompany = async () => {
  if (!imgs.value.length) {
    Dialog.alert("提示", "请上传营业执照", true, true);
    return;
  }
  const imgUrl = await uploadImg([...imgs.value]);
  const [err, data] = await $api.company.claimCompany({
    id: companyInfo.id,
    companyName: companyInfo.companyName,
    images: imgUrl,
  });
  if (data.succeeded) {
    Dialog.alert("审核中", "已收到您的认证申请,请耐心等待", true, false, () => {
      (showClaimForm.value = false), uploadRef.value.clearFiles();
      hideUpload.value = false;
      imgs.value = [];
    });
  }
};
const showForm = () => {
  if (!isLogin.value) {
    store.commit("toggleLoginForm", true);
  } else {
    showClaimForm.value = true;
  }
};
</script>
<style lang="less" scoped>
.avatar-uploader {
  text-align: center;
  margin-top: 30px;
}

:deep(.el-upload) {
  border: none;
}
:deep(.disable .el-upload--picture-card) {
  display: none;
}
:deep(.el-upload--picture-card) {
  width: 100%;
}
:deep(.el-upload-list__item) {
  width: 225px;
  height: 154px;
  object-fit: cover;
}
.operate-container {
  width: 100%;
  height: 100%;
}
.border-none {
  border: none;
}
.notshow {
  display: none;
}
.cert-pic {
  width: 225px;
  height: 154px;
}
.renzheng {
  bottom: 30px;
}
.article-list {
  padding: 0 20px 0 30px;
}
.article-item {
  padding: 35px 0 30px;
}
.pagnation {
  text-align: center;
}
.article-info {
  text-align: left;
}
.submit-btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 20px;
}
.submit-btn > button {
  background-color: #1188f7;
  color: white;
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 990;
  background: rgba(0.1, 0.1, 0.1, 0.1);
}
.preview-img {
  height: 100%;
  width: 100%;
}
.info_box {
  position: relative;
}
.url,.address{
  width: 62%;
  line-height: 2;
}



/deep/.ent_brief p{margin-top: 20px;text-align: justify;letter-spacing:1px;}
</style>

import { onMounted, reactive } from "vue"
import {useRoute} from "vue-router"
import $api from "@/api"
export default function useCompanyInfo() { 
    const route=useRoute()
    const companyId=route.query.id

    const companyInfo=reactive({})
    const getCompanyInfo=async()=>{
        const [err,data]=await $api.company.getCompanyInfo({id:companyId})
        if(!data.succeeded) return;
        Object.assign(companyInfo,data.data)
        
    }
    onMounted(getCompanyInfo)
    return {
        companyInfo
    }
 }
import {onMounted, reactive, ref} from "vue"
import {useRoute} from "vue-router"
import $api from "@/api"
export default function useCompanyArticle(){
    const route=useRoute()
    const companyArticles=reactive({
        list:[],
        totalCount:0
    })
    const queryParams=reactive({
        VendorsId:route.query.id,
        PageIndex:1,
        PageSize:15
    })
    const getCompanyArticle=async()=>{
        
        const [err,data]=await $api.company.getCompanyArticle(queryParams);
        if(!data.succeeded) return;
        companyArticles.list=data.data.items.sort((a,b)=>new Date(b.createdTime)-new Date(a.createdTime))
        companyArticles.totalCount=data.data.totalCount
    }
    onMounted(getCompanyArticle)
    return {
        queryParams,companyArticles,getCompanyArticle
    }
}